/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';
import { useMe } from '../AppContextProvider';
import {
  getTranscriptContent,
  getEditedByText,
  identifySpeakers,
  formatTime,
} from './utils';
import ManualCreatingHighlight from '../ManualCreatingHighlight';
import TranscriptionImageBlock from '../TranscriptionImageBlock';
import AudioPlayer from '../AudioPlayer';
import { AudioBox } from './styledComponents';
import EditTranscriptDrawer from './components/EditTranscriptDrawer';
import RenderParagraphDesktopView from './components/RenderParagraphDesktopView';
import RenderParagraphMobileView from './components/RenderParagraphMobileView';
import RenderAudioPlayer from './components/RenderAudioPlayer';
import type { ParagraphToEdit } from './components/EditTranscriptDrawer';
import type {
  ParagraphHistoryType,
  ParagraphType,
  TranscriptType,
  HighlightType,
} from '../../shared/types/transcript';

type TranscriptViewProps = {
  transcript: TranscriptType;
  transcriptParagraphs: ParagraphType[];
  updateParagraphText: (
    paragraphUuid: string,
    newLastHistory: ParagraphHistoryType
  ) => void;
  selectedHighlight: HighlightType | null;
  setSelectedHighlight: React.Dispatch<React.SetStateAction<HighlightType | null>>;
  audio: HTMLAudioElement | null;
  isPlayingMain: boolean;
  setIsPlayingMain: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayingParagraph: boolean;
  setIsPlayingParagraph: React.Dispatch<React.SetStateAction<boolean>>;
  isResearchAnalyst: boolean;
};

const TranscriptView = ({
  transcript,
  transcriptParagraphs,
  updateParagraphText,
  selectedHighlight,
  setSelectedHighlight,
  audio,
  isPlayingMain,
  setIsPlayingMain,
  isPlayingParagraph,
  setIsPlayingParagraph,
  isResearchAnalyst,
}: TranscriptViewProps) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedParagraphToEdit, setSelectedParagraphToEdit] = useState<ParagraphToEdit | null>(null);
  const [selectedParagraph, setSelectedParagraph] = useState<ParagraphType | null>(null);
  const [selectedText, setSelectedText] = useState<string | null>(null);

  const { me } = useMe();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isFileAText = transcript?.call?.transcript?.isFileAText;
  const isDocument = transcript.call.type === 'document';

  const { status } = transcript.call.transcript;

  const transcriptStatus = {
    isInProcess: status.value === 'Transcription In Process',
    isTranscribed: status.value === 'ChatGPT In Process',
    isTextInProcess: status.value === 'Text Transcription In Process',
    isCompleted: status.value === 'Completed',
    isFailed: status.value === 'Failed',
  };

  const handleToggleDrawer = useCallback((newState: boolean) => {
    setDrawerOpen(newState);
  }, []);

  const handleEditParagraph = (paragraph: ParagraphType) => {
    setSelectedParagraphToEdit({
      uuid: paragraph.uuid,
      startTime: paragraph.startTime,
      endTime: paragraph.endTime,
      speaker: identifySpeakers(transcript.analysts, transcript.experts, paragraph.speaker),
      participant: paragraph.participant,
      text: paragraph.text,
      cleanedOriginalText: paragraph.cleanedOriginalText,
    });
    handleToggleDrawer(true);
  };

  const handleSelectHighlight = useCallback(
    (highlight: HighlightType) => {
      const isSelected = selectedHighlight?.uuid === highlight.uuid;
      setSelectedHighlight(isSelected ? null : highlight);
      if (!isSelected) {
        const element = document.getElementById(`highlight-${highlight.uuid}`);
        if (element) {
          const elementRect = element.getBoundingClientRect();
          const elementIsVisible = elementRect.top >= 0
            && elementRect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
          if (!elementIsVisible) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
          }
        }
      }
    },
    [selectedHighlight, setSelectedHighlight],
  );

  const handleSelectText = (
    paragraphObj: ParagraphType,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (isResearchAnalyst || transcript.call.review.status.value === 'Reviewed') {
      return;
    }

    const selection = window.getSelection();
    if (selection && selection.toString().length > 5) {
      const contextMenu = document.getElementById('contextMenu');
      if (contextMenu) {
        contextMenu.style.display = 'flex';
        contextMenu.style.top = `${event.clientY + 15}px`;
        contextMenu.style.left = `${event.clientX - 180}px`;
        setSelectedText(selection.toString());
        setSelectedParagraph(paragraphObj);
      }
    } else {
      setSelectedText(null);
      document.getElementById('contextMenu')!.style.display = 'none';
    }
  };

  const renderParagraph = (paragraph: ParagraphType) => {
    const transcriptContent = getTranscriptContent(
      paragraph,
      transcript.highlights,
      isResearchAnalyst,
      handleSelectHighlight,
    );

    const editedBy = getEditedByText(paragraph, isResearchAnalyst, me);

    const renderProps = {
      isResearchAnalyst,
      isTranslated: paragraph.isTranslated,
      disableAudio: transcript.disableAudio,
      AudioPlayer: (
        <RenderAudioPlayer
          audio={audio}
          isPlayingMain={isPlayingMain}
          setIsPlayingMain={setIsPlayingMain}
          isPlayingParagraph={isPlayingParagraph}
          setIsPlayingParagraph={setIsPlayingParagraph}
          activeId={activeId}
          setActiveId={setActiveId}
          paragraph={paragraph}
        />
      ),
      speaker: identifySpeakers(transcript.analysts, transcript.experts, paragraph.speaker),
      participant: paragraph.participant,
      startTime: formatTime(paragraph.startTime),
      endTime: formatTime(paragraph.endTime),
      isFileAText,
      isEmptyParagraph: paragraph.text === '',
      originalText: paragraph.originalText,
      transcriptContent,
      isDocument,
    };

    return (
      <Box key={paragraph.uuid}>
        {isMobile ? (
          <RenderParagraphMobileView {...renderProps} />
        ) : (
          <RenderParagraphDesktopView
            {...renderProps}
            paragraphId={paragraph.uuid}
            handleEditParagraph={() => handleEditParagraph(paragraph)}
            selectedParagraph={selectedHighlight?.paragraph?.uuid === paragraph?.uuid}
            handleClickParagraph={handleSelectText}
            editedBy={editedBy}
            paragraph={paragraph}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      <EditTranscriptDrawer
        drawerOpen={drawerOpen}
        paragraphToEdit={selectedParagraphToEdit}
        handleToggleDrawer={handleToggleDrawer}
        updateParagraphText={updateParagraphText}
        audio={audio}
        activeId={activeId}
        setActiveId={setActiveId}
        isPlayingMain={isPlayingMain}
        setIsPlayingMain={setIsPlayingMain}
        isPlayingParagraph={isPlayingParagraph}
        setIsPlayingParagraph={setIsPlayingParagraph}
        isFileAText={isFileAText}
        isDocument={isDocument}
      />
      <ManualCreatingHighlight
        selectedParagraph={selectedParagraph}
        selectedText={selectedText}
        audio={audio}
        isPlayingMain={isPlayingMain}
        setIsPlayingMain={setIsPlayingMain}
        isPlayingParagraph={isPlayingParagraph}
        setIsPlayingParagraph={setIsPlayingParagraph}
        isDocument={isDocument}
      />
      <Box sx={{ padding: '0 12px' }}>
        {!isMobile && (
          <Box height={(isResearchAnalyst && transcript.disableAudio) || isFileAText ? 1 : 82} id="StaticAudioPlayer">
            {(isResearchAnalyst && transcript.disableAudio) || isFileAText ? <Box /> : (
              <AudioBox>
                <AudioPlayer
                  audio={audio}
                  isPlayingMain={isPlayingMain}
                  setIsPlayingMain={setIsPlayingMain}
                  isPlayingParagraph={isPlayingParagraph}
                  setIsPlayingParagraph={setIsPlayingParagraph}
                  playParagraph={false}
                />
              </AudioBox>
            )}
          </Box>
        )}
      </Box>
      <Box id="PlayerAnchor">
        {transcriptStatus.isFailed && <TranscriptionImageBlock failed />}
        {(transcriptStatus.isInProcess || transcriptStatus.isTranscribed || transcriptStatus.isTextInProcess) && (
          <TranscriptionImageBlock failed={false} />
        )}
        {transcriptStatus.isCompleted && (
          <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: isMobile ? '40px' : 0 }}>
            {transcriptParagraphs.map(renderParagraph)}
          </Box>
        )}
      </Box>
    </>
  );
};

export default TranscriptView;
