import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Typography,
  // FormControl,
  // Select,
  // MenuItem,
  // SelectChangeEvent,
  TextField,
  InputAdornment,
} from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import palette from '../../theme/palette';
import { ReactComponent as FlagIcon } from '../../assets/icons/flagIcon.svg';
import { ReactComponent as TickCircleIcon } from '../../assets/icons/tickCircleIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeIcon.svg';
import ParagraphView from '../ParagraphView';
import CallDetailsItem from '../CallDetailsItem';
import { ParagraphType } from '../../shared/types/transcript';
import { AppContext, useMe } from '../AppContextProvider';
import { CREATE_HIGHLIGHT_MUTATION } from '../../queries/transcript/transcript';
// import TranscriptView from '../TranscriptView';

type ConfidenceInputType = {
  onChange: any
};

const ConfidenceInput = ({ onChange }: ConfidenceInputType) => {
  const [confidence, setConfidence] = useState('100');
  const getTextColor = (value: number) => {
    if (value >= 80 && value <= 100) {
    // eslint-disable-next-line
      return 'colors.success03';
    // eslint-disable-next-line
    } else if (value >= 60 && value < 80) {
      return 'colors.warning04';
    } else {
      return 'colors.error04';
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericValue = input.replace(/\D/g, '');

    if (numericValue === '' || (Number(numericValue) >= 0 && Number(numericValue) <= 100)) {
      setConfidence(numericValue);
      onChange(numericValue);
    }
  };
  const handleBlur = () => {
    if (confidence === '') {
      setConfidence('0');
      onChange(0);
    }
  };

  return (
    <TextField
      value={confidence}
      onChange={handleChange}
      onBlur={handleBlur}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{ marginLeft: '-90px' }}
          >
            %
          </InputAdornment>
        ),
      }}
      sx={{
        fontWeight: 700,
        fontSize: 'inherit',
        input: {
          color: getTextColor(Number(confidence)),
        },
      }}
    />
  );
};

// type DropdownProps = {
//   options: string[];
//   value: string;
//   // eslint-disable-next-line
//   onChange: (value: string) => void;
//   placeholder: string;
//   inCard?: boolean;
//   disabled?: boolean;
// };

// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: '275px',
//       width: '310px',
//       borderRadius: '8px',
//       boxShadow: '0px 8px 9px rgba(226, 226, 226, 0.35)',
//     },
//   },
// };

// const Dropdown: React.FC<DropdownProps> = ({
//   options,
//   value,
//   onChange,
//   placeholder,
//   inCard = false,
//   disabled = false,
// }) => {
//   const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
//     onChange(event.target.value as string);
//   };

//   return (
//     <FormControl sx={{ minWidth: inCard ? '100px' : '165px' }}>
//       <Select
//         value={value}
//         // @ts-ignore
//         onChange={handleChange}
//         displayEmpty
//         disabled={disabled}
//         autoFocus={false}
//         inputProps={{ 'aria-label': 'Without label' }}
//         IconComponent={ExpandMoreIcon}
//         MenuProps={MenuProps}
//         sx={{
//           '&.MuiOutlinedInput-root': {
//             paddingLeft: 0,
//             paddingRight: 0,
//           },
//           '& .MuiOutlinedInput-input': {
//             paddingTop: 0,
//             paddingBottom: 0,
//           },
//           '& .MuiOutlinedInput-notchedOutline': {
//             border: 'none',
//           },
//           '&:hover .MuiOutlinedInput-notchedOutline': {
//             border: 'none',
//           },
//           '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//             border: 'none',
//           },
//         }}
//         renderValue={selected => {
//           if (!selected) {
//             return (
//               <Box display="flex" alignItems="center">
//                 <Typography
//                   variant="overline"
//                   sx={{
//                     textTransform: 'none',
//                     ...(inCard ? {
//                       fontSize: '14px', fontWeight: '500', lineHeight: '26px',
//                     } : {
//                       marginRight: '6px',
//                     }),
//                   }}
//                 >
//                   {placeholder}
//                 </Typography>
//               </Box>
//             );
//           }
//           return (
//             <Typography
//               variant="overline"
//               color="colors.gray08"
//               sx={{
//                 textTransform: 'none',
//                 ...(inCard ? {
//                   fontSize: '14px', fontWeight: '500', lineHeight: '26px',
//                 } : {
//                   marginRight: '6px',
//                 }),
//               }}
//             >
//               {selected}
//             </Typography>
//           );
//         }}
//       >
//         {options.map(option => (
//           <MenuItem key={option} value={option}>
//             {option}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

const ContexMenu = styled(Box)({
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  zIndex: 1000,
  backgroundColor: palette.colors.gray02,
  borderRadius: '4px',
  padding: '4px 10px',
  cursor: 'pointer',
});

const DrawerContainer = styled(Drawer)({
  width: 850,
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: 850,
    boxSizing: 'border-box',
    boxShadow: '-11px 0px 60px rgba(0, 0, 0, 0.25)',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
});

const CloseButton = styled(IconButton)({
  padding: 0,
  marginRight: '20px',
});

const FooterBlock = styled(Box)({
  borderTop: '1px solid',
  borderTopColor: palette.colors.gray03,
  backgroundColor: '#FAFDFF',
  padding: '16px 32px',
});

const CreateFullButton = styled(Button)({
  backgroundColor: palette.primary.main,
  borderRadius: '8px',
  height: 52,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '26px',
  letterSpacing: 0,
  padding: '12px',
  marginTop: '8px',
  '&:hover': {
    backgroundColor: palette.primary.dark,
  },
});

type ManualCreatingHighlightProps = {
  selectedParagraph: ParagraphType | null
  selectedText: string | null
  audio: HTMLAudioElement | null
  isPlayingMain: boolean
  setIsPlayingMain: React.Dispatch<React.SetStateAction<boolean>>
  isPlayingParagraph: boolean
  setIsPlayingParagraph: React.Dispatch<React.SetStateAction<boolean>>
  isDocument: boolean
}

const ManualCreatingHighlight = ({
  selectedParagraph,
  selectedText,
  audio,
  isPlayingMain,
  setIsPlayingMain,
  isPlayingParagraph,
  setIsPlayingParagraph,
  isDocument,
}: ManualCreatingHighlightProps) => {
  const [open, setOpen] = React.useState(false);
  const { me } = useMe();

  const stopClick = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();
  };

  const toggleDrawer = (value: boolean) => (event?: React.KeyboardEvent | React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
    }
    setOpen(value);
  };

  const handleClose = () => {
    toggleDrawer(false)();

    const contextMenu = document.getElementById('contextMenu');
    if (contextMenu) {
      contextMenu.style.display = 'none';
    }
  };

  // const options = [
  //   'Manual',
  //   'MNPI',
  //   'Shadow Trading',
  //   'Contact Info',
  //   'Meeting',
  //   'Reluctance',
  //   'Tippee',
  //   'Preamble',
  // ];
  const [createHighlight] = useMutation(CREATE_HIGHLIGHT_MUTATION);
  // const [selectedOption, setSelectedOption] = useState<string>(options[0]);
  // eslint-disable-next-line
  const [confidence, setConfidence] = useState('100');
  // eslint-disable-next-line
  const handleChangeConfidence = (value: string) => {
    setConfidence(value);
  };

  const { refetchTranscript } = useContext(AppContext);

  const handleCreate = () => {
    console.log(selectedText, selectedParagraph?.uuid, confidence);
    createHighlight({
      variables: {
        confidence: Number(confidence),
        categories: ['Manual'],
        phrase: selectedText,
        paragraphUuid: selectedParagraph?.uuid,
      },
      onCompleted: () => {
        if (refetchTranscript) {
          refetchTranscript();
          handleClose();
        }
      },
    });
  };

  return (
    <Box display="flex" onClick={stopClick}>
      <ContexMenu id="contextMenu" onClick={toggleDrawer(true)}>
        <FlagIcon />
        <Typography
          variant="body1"
          color="colors.gray08"
          sx={{ fontWeight: 400, marginLeft: '4px' }}
        >
          Create Item of Interest
        </Typography>
      </ContexMenu>
      <DrawerContainer
        anchor="right"
        open={open}
        onClose={handleClose}
      >
        <Box display="flex" alignItems="center" sx={{ padding: '20px 36px' }}>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <Typography variant="h3" color="colors.gray10" sx={{ fontSize: '24px' }}>
            Create Item of Interest
          </Typography>
        </Box>
        <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
        <Box display="flex" flexDirection="column" flexGrow={1} sx={{ padding: '24px 32px' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="caption" color="colors.gray06">
              Transcript
            </Typography>
            <Typography variant="caption" color="colors.info05" sx={{ fontWeight: 400, fontStyle: 'italic' }}>
              Detected by&nbsp;
              { `${me.firstName} ${me.lastName}` }
            </Typography>
          </Box>
          {selectedParagraph && (
            <ParagraphView
              paragraphId={selectedParagraph.uuid}
              speaker={selectedParagraph.speaker}
              startTime={selectedParagraph.startTime}
              endTime={selectedParagraph.endTime}
              highlightedParagraph={selectedText}
              isFileAText={false}
              audio={audio}
              analysts={[]}
              experts={[]}
              participant={selectedParagraph.participant}
              isPlayingMain={isPlayingMain}
              setIsPlayingMain={setIsPlayingMain}
              isPlayingParagraph={isPlayingParagraph}
              setIsPlayingParagraph={setIsPlayingParagraph}
              isDocument={isDocument}
            />
          )}

          <Divider flexItem sx={{ margin: '25px 0 30px', borderColor: 'colors.gray03' }} />
          <Box display="flex" flexWrap="wrap">
            <CallDetailsItem title="Category" sx={{ marginRight: '64px' }} isTypography>
              {/* <Dropdown
                options={options}
                value={selectedOption}
                onChange={setSelectedOption}
                placeholder="Select an option"
                disabled
              /> */}
              Manual
            </CallDetailsItem>
            <CallDetailsItem title="Confidence" sx={{ marginRight: '64px' }}>
              <ConfidenceInput onChange={handleChangeConfidence} />
            </CallDetailsItem>
          </Box>
        </Box>
        <FooterBlock>
          <CreateFullButton onClick={handleCreate} variant="contained" startIcon={<TickCircleIcon />} fullWidth>
            Create Item of Interest
          </CreateFullButton>
        </FooterBlock>
      </DrawerContainer>
    </Box>
  );
};

export default ManualCreatingHighlight;
