import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs, { Dayjs } from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import {
  Box, Typography, Chip,
} from '@mui/material';
import {
  DateRange, DesktopDateRangePicker, MobileDateRangePicker, SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { LocalizationProvider, PickersShortcutsProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import utc from 'dayjs/plugin/utc';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { ReactComponent as CalendarPickerIcon } from '../../assets/icons/calendarPickerIcon.svg';
import {
  FilterButton, Accordion, AccordionSummary, AccordionDetails, ClearAllTypography,
} from './styledComponents';
import shortcutsItems from './constants';
// import ReviewerFilter from '../ReviewerFilter';

dayjs.extend(utc);

const CustomRangeShortcuts = (props: PickersShortcutsProps<DateRange<Dayjs>>) => {
  const {
    items, onChange, isValid, changeImportance,
  } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map(item => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue, changeImportance ?? 'accept', item);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box sx={{ gridRow: 3, gridColumn: 2 }}>
      <Box display="flex" flexWrap="wrap" sx={{ padding: '0 16px 8px 16px' }}>
        {resolvedItems.map(item => (
          <Box key={item.label} sx={{ padding: '0 8px 8px 0' }}>
            <Chip {...item} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const CallsFilter = ({
  setFilterData, reviewedCount,
}: {
  setFilterData: any, reviewedCount: number,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenFilters = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const {
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      dateRange: [null, null],
    },
  });

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  const handleResetFilters = () => {
    setDateRange([null, null]);
  };

  /*
  useEffect(() => {
    setDateRange([dayjs().subtract(30, 'day').utc().startOf('day'), dayjs().utc().endOf('day')]);
  }, []);
  */

  useEffect(() => {
    // if ((dateRange[0]! === null && dateRange[1] !== null) || (dateRange[1] === null && dateRange[0] === null)) {
    setFilterData({
      dateRangeStart: dateRange[0] !== null ? dateRange[0].utc().add(2, 'hour').startOf('day') : null,
      dateRangeEnd: dateRange[1] !== null ? dateRange[1].utc().add(2, 'hour').endOf('day') : null,
    });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  return (
    <Box pb="4px" sx={{ borderBottom: '1px solid', borderBottomColor: 'colors.gray03' }}>
      <Accordion expanded={expanded === 'filterBox'} onChange={handleOpenFilters('filterBox')}>
        <AccordionSummary aria-controls="filterBox-content" id="filterBox-header">
          <Typography variant="caption" color="colors.gray06" display="none">
            Reviewed Content (
            {reviewedCount}
            )
          </Typography>
          <FilterButton>
            <FilterListRoundedIcon sx={{ color: expanded ? 'primary.main' : 'colors.gray06' }} />
            <Box display="flex" mx="8px" sx={{ color: expanded ? 'primary.main' : 'colors.gray08' }}>Filters</Box>
            <ExpandMoreIcon sx={{ color: expanded ? 'primary.main' : 'colors.gray06', ...(expanded && { transform: 'rotate(180deg)' }) }} />
          </FilterButton>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb="12px">
            <Typography variant="subtitle2">
              Filter Content
            </Typography>
            <ClearAllTypography variant="caption" color="colors.info05" onClick={handleResetFilters}>
              Clear all
            </ClearAllTypography>
          </Box>
          <form>
            {/* <Typography variant="caption" color="colors.gray06" sx={{ display: 'flex', marginBottom: '2px' }}>
              Compliance Reviewer
            </Typography>
            <Box display="flex" mb="16px">
              <ReviewerFilter
                meUuid="123"
                handleSelect={() => {}}
                fieldValue={['123']}
                blockWidth={370}
                showNoReviewerOption
              />
            </Box> */}
            <Typography variant="caption" color="colors.gray06" sx={{ display: 'flex', marginBottom: '2px' }}>
              Content Date
            </Typography>
            <Box className="date-range-picker">
              <Controller
                control={control}
                name="dateRange"
                render={({ field }) => (
                  isMobile ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDateRangePicker
                        {...field}
                        // @ts-ignore
                        value={dateRange}
                        // defaultValue={[dayjs().subtract(30, 'day'), dayjs()]}
                        onChange={newValue => {
                          // @ts-ignore
                          setDateRange(newValue);
                        }}
                        slots={{
                          field: SingleInputDateRangeField,
                          shortcuts: CustomRangeShortcuts,
                        }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              endAdornment: <CalendarPickerIcon />,
                              onBeforeInput: disableKeyboardEntry,
                              onKeyDown: disableKeyboardEntry,
                            },
                          },
                          shortcuts: {
                            items: shortcutsItems,
                          },
                          actionBar: { actions: [] },
                        }}
                        closeOnSelect
                      />
                    </LocalizationProvider>
                  ) : (
                    <DesktopDateRangePicker
                      {...field}
                      value={dateRange}
                      // defaultValue={[dayjs().subtract(30, 'day'), dayjs()]}
                      onChange={newValue => {
                        setDateRange(newValue);
                      }}
                      slots={{ field: SingleInputDateRangeField }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: <CalendarPickerIcon />,
                            onBeforeInput: disableKeyboardEntry,
                            onKeyDown: disableKeyboardEntry,
                          },
                        },
                        shortcuts: {
                          items: shortcutsItems,
                        },
                        actionBar: { actions: [] },
                      }}
                      calendars={2}
                      sx={{ width: '100%' }}
                    />
                  )
                )}
              />
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CallsFilter;
