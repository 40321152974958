/* eslint-disable no-unused-vars */
import React from 'react';

// * Components
import CallItemCard from '../Cards/CallItemCard';

// * Interfaces
import type { TranscriptType } from '../../shared/types/transcript';

type CallListProps = {
  transcripts: TranscriptType[];
  onSelect: (uuid: string) => void;
  selectedTranscriptId?: string;
  isResearchAnalyst?: boolean;
  openDrawerMobile?: boolean;
  setOpenDrawerMobile?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CallList: React.FC<CallListProps> = ({
  transcripts, onSelect, selectedTranscriptId, isResearchAnalyst,
  openDrawerMobile, setOpenDrawerMobile,
}) => (
  <>
    {transcripts.map(transcript => (
      <CallItemCard
        key={transcript.uuid}
        uuid={transcript.uuid}
        accountName={transcript.call.account.name}
        accountAvatarLogo={transcript.call.account.settings.avatarLogo}
        review={transcript.call.review}
        title={transcript.call.name}
        datetime={transcript.call.datetime}
        network={transcript.call.network.value}
        transcriptionStatus={transcript.call.transcript?.status.value}
        pendingHighlights={transcript.pendingCount}
        resolvedHighlights={transcript.resolvedCount}
        aiResolvedHighlights={transcript.aiResolvedCount}
        onClick={() => onSelect(transcript.uuid)}
        openDrawerMobile={openDrawerMobile}
        setOpenDrawerMobile={setOpenDrawerMobile}
        selected={transcript.uuid === selectedTranscriptId}
        expertName={transcript.call.expertName}
        analystName={transcript.call.analystName}
        reviewStatus={transcript.call.review.status.value}
        isResearchAnalyst={isResearchAnalyst}
        isInSample={transcript.call.inSample}
        type={transcript.call.type}
        createdByResearchAnalyst={transcript.call?.createdByResearchAnalyst}
      />
    ))}
  </>
);

CallList.defaultProps = {
  selectedTranscriptId: undefined,
  isResearchAnalyst: false,
  openDrawerMobile: false,
  setOpenDrawerMobile: () => {},
};

export default CallList;
