import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Typography,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import palette from '../../theme/palette';

export const FilterButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
  color: palette.colors.gray08,
  padding: '8px',
});

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  backgroundColor: 'transparent',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
});

export const AccordionSummary = styled(MuiAccordionSummary)({
  backgroundColor: 'transparent',
  padding: 0,
  minHeight: 40,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    height: 40,
    margin: 0,
    '&:hover': {
      '& div': {
        color: palette.primary.main,
      },
      '& svg': {
        color: palette.primary.main,
      },
    },
  },
});

export const AccordionDetails = styled(MuiAccordionDetails)({
  backgroundColor: palette.colors.gray01,
  padding: '12px',
  borderTop: '1px solid',
  borderTopColor: palette.colors.gray03,
});

export const ClearAllTypography = styled(Typography)({
  cursor: 'pointer',
});
