import React, { useContext, useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NoteAddIcon } from '../../assets/icons/noteAddIcon.svg';
import { ReactComponent as NoteRemoveIcon } from '../../assets/icons/noteRemoveIcon.svg';
import palette from '../../theme/palette';
import {
  CallItem, CallTitle, HighlightDot, HighlightStatus, NetworkLabel, NetworkValue, ScheduledCardStatus,
} from './styledComponents';
import { AvatarSmall, SelectedTypography } from '../AccountSwitcher/styledComponents';
import { formatTime } from '../../shared/utils/dateHelpers';
import { AppContext, useMe } from '../AppContextProvider';
import paths from '../../paths/paths';
import IndicateType from './components/IndicateType';

// * Interfaces
import type { CallTypes } from '../../shared/types/transcript';

const statusList = [
  { status: 'In Progress', color: 'colors.success04' },
  { status: 'Canceled', color: 'colors.warning04' },
  { status: 'Suspended', color: 'colors.error03' },
];

type StatusBarProps = {
  status: string;
  isInSample: boolean;
  isResearchAnalyst: boolean;
};

const StatusBar = ({ status, isInSample, isResearchAnalyst }: StatusBarProps) => {
  const item = statusList.find(i => i.status === status);
  const icon = isInSample ? <NoteAddIcon /> : <NoteRemoveIcon />;
  const text = isInSample ? 'Included in sample.' : 'Not included in sample.';

  return (
    <Box display="flex" flexDirection="column">
      {item && (
        <Box display="flex" alignItems="center" mt="8px">
          <HighlightDot sx={{ backgroundColor: item.color }} />
          <ScheduledCardStatus variant="body1" color={item.color}>
            {item.status}
          </ScheduledCardStatus>
        </Box>
      )}
      {!isResearchAnalyst && (
        <Box display="flex" alignItems="center" mt="8px">
          {icon}
          <HighlightStatus variant="body1" color={palette.colors.gray08} sx={{ fontWeight: 500 }}>
            {text}
          </HighlightStatus>
        </Box>
      )}
    </Box>
  );
};

type ScheduledCallItemCardProps = {
  uuid: string;
  accountName: string;
  accountAvatarLogo: string;
  status: string;
  isInSample: boolean;
  title: string;
  datetime: string;
  network: string;
  selected: boolean;
  expertName?: string;
  analystName?: string;
  isResearchAnalyst?: boolean;
  type: CallTypes;
  onClick: React.Dispatch<React.SetStateAction<string | undefined>>;
  openDrawerMobile?: boolean;
  setOpenDrawerMobile?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ScheduledCallItemCard = ({
  uuid,
  accountName,
  accountAvatarLogo,
  status,
  isInSample,
  title,
  datetime,
  network,
  selected,
  expertName,
  analystName,
  isResearchAnalyst,
  type,
  onClick,
  openDrawerMobile,
  setOpenDrawerMobile,
}: ScheduledCallItemCardProps) => {
  const navigate = useNavigate();
  const { me } = useMe();
  const { refetchScheduledCalls, refetchTranscript } = useContext(AppContext);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date().toISOString().slice(0, 16);
      const dateToCompare = datetime.slice(0, 16);
      if (now === dateToCompare && refetchScheduledCalls && refetchTranscript) {
        refetchTranscript();
        refetchScheduledCalls();
      }
    }, 30000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  const handleSelectCall = () => {
    onClick(uuid);
    setOpenDrawerMobile?.(!openDrawerMobile);
    navigate(paths.home.reverse({ transcriptId: uuid }));
  };

  return (
    <CallItem onClick={handleSelectCall} className={selected ? 'selected-call-item' : ''}>
      {me.isAcaReviewer && (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <NetworkLabel variant="body1" color="colors.gray08">
              Client Name
            </NetworkLabel>
            <Box display="flex" alignItems="center" textTransform="capitalize">
              {accountAvatarLogo && <AvatarSmall alt={accountName} src={accountAvatarLogo} />}
              <SelectedTypography variant="body1" color="secondary.main" ml="4px">
                {accountName}
              </SelectedTypography>
            </Box>
          </Box>
          <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />
        </>
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <IndicateType type={type} />
          <Typography
            variant="body1"
            color="colors.gray06"
            sx={{ fontSize: '12px', lineHeight: '18px' }}
          >
            {formatTime(datetime)}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="colors.gray06"
          display={type === 'meeting' && network === 'Default' ? 'none' : 'flex'}
          sx={{ fontSize: '12px', lineHeight: '18px' }}
        >
          {network}
        </Typography>
      </Box>
      <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />
      <Box display="flex" alignItems="center">
        {type === 'call' && (
          <>
            <Box display="flex" flexDirection="column" width="50%" mr="2px">
              <NetworkLabel variant="body1" color="colors.gray08">
                Expert Name
              </NetworkLabel>
              <NetworkValue variant="body1" color="colors.gray08">
                {expertName || 'Not Available'}
              </NetworkValue>
            </Box>
            <Box display="flex" flexDirection="column" width="50%" ml="2px">
              <NetworkLabel variant="body1" color="colors.gray08">
                Analyst
              </NetworkLabel>
              <NetworkValue variant="body1" color="colors.gray08">
                {analystName || 'Not Available'}
              </NetworkValue>
            </Box>
          </>
        )}
      </Box>
      {type === 'call' && <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />}
      <CallTitle
        variant="body2"
        color="colors.gray10"
        sx={{ textDecorationLine: status === 'Canceled' ? 'line-through' : 'none' }}
      >
        {title}
      </CallTitle>
      <StatusBar
        status={status}
        isInSample={isInSample}
        isResearchAnalyst={isResearchAnalyst || false}
      />
    </CallItem>
  );
};

ScheduledCallItemCard.defaultProps = {
  expertName: null,
  analystName: null,
  isResearchAnalyst: false,
  openDrawerMobile: false,
  setOpenDrawerMobile: false,
};

export default ScheduledCallItemCard;
