import React, { useState } from 'react';

// * MUI
import {
  IconButton, MenuItem, Menu, Typography, Box,
} from '@mui/material';

// * Utils
import palette from '../../../theme/palette';

const RATE_OPTIONS = [1, 1.5, 2, 3];

interface PlaybackRateButtonProps {
  // eslint-disable-next-line no-unused-vars
  handleChangePlaybackRate: (newRate: number) => void
}

const PlaybackRateButton = ({
  handleChangePlaybackRate,
}: PlaybackRateButtonProps) => {
  const [rate, setRate] = useState<number>(1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeRate = (newRate: number) => {
    setRate(newRate);
    handleChangePlaybackRate(newRate);
    handleCloseMenu();
  };

  return (
    <>
      <IconButton
        disableRipple
        size="small"
        aria-label="Playback Speed"
        aria-controls="playbackrate-menu"
        aria-haspopup="true"
        onClick={handleOpenMenu}
        sx={{ color: palette.secondary.main }}
      >
        <Box
          width={36}
          height={24}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2" noWrap>
            {`${rate}x`}
          </Typography>
        </Box>
      </IconButton>

      <Menu
        id="playbackrate-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
      >
        {RATE_OPTIONS.map(option => (
          <MenuItem
            key={option}
            selected={rate === option}
            onClick={() => handleChangeRate(option)}
          >
            {`${option}x`}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default PlaybackRateButton;
