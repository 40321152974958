/* eslint-disable import/order */
import React, { useContext } from 'react';

// * MUI
import { Box, Typography, Divider } from '@mui/material';

// * Components
import {
  CallItem, CallTitle, HighlightCount, HighlightDot, HighlightStatus, NetworkLabel, NetworkValue,
} from './styledComponents';
import { AvatarSmall, SelectedTypography } from '../AccountSwitcher/styledComponents';
import ComplianceReviewer from '../ComplianceReviewer';
import IndicateType from './components/IndicateType';

// * Hooks & Utils
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from '../../paths/paths';
import { formatDateTimeToUserTimezone } from '../../shared/utils/dateHelpers';
import { AppContext, useMe } from '../AppContextProvider';

// * Icons
import { ReactComponent as NoteRemoveIcon } from '../../assets/icons/noteRemoveIcon.svg';
import { ReactComponent as FailedIcon } from '../../assets/icons/failedIcon.svg';
import { ReactComponent as InProgressIcon } from '../../assets/icons/inProgressIcon.svg';
import { ReactComponent as UploadedOnRPIcon } from '../../assets/icons/uploadedOnRPIcon.svg';

// * Mutations
import { ASSIGN_USER_TO_REVIEW_MUTATION } from '../../queries/transcript/transcript';

// * Interfaces
import type { CallTypes, CallType } from '../../shared/types/transcript';

type CallItemCardProps = {
  uuid: string;
  accountName: string;
  accountAvatarLogo?: string;
  review: CallType['review']
  title: string;
  datetime: string;
  network: string;
  transcriptionStatus: string;
  selected: boolean;
  pendingHighlights?: number;
  resolvedHighlights?: number;
  aiResolvedHighlights?: number;
  expertName?: string;
  analystName?: string;
  reviewStatus: string;
  isResearchAnalyst?: boolean;
  isInSample: boolean;
  type: CallTypes,
  createdByResearchAnalyst: boolean,
  onClick: React.Dispatch<React.SetStateAction<string | undefined>>;
  openDrawerMobile?: boolean;
  setOpenDrawerMobile?: React.Dispatch<React.SetStateAction<boolean>>;
};

const CallItemCard = ({
  uuid,
  accountName,
  accountAvatarLogo,
  review,
  title,
  datetime,
  network,
  transcriptionStatus,
  selected,
  pendingHighlights,
  resolvedHighlights,
  aiResolvedHighlights,
  expertName,
  analystName,
  reviewStatus,
  isResearchAnalyst,
  isInSample,
  onClick,
  openDrawerMobile,
  type,
  createdByResearchAnalyst,
  setOpenDrawerMobile,
}: CallItemCardProps) => {
  const navigate = useNavigate();
  const { me } = useMe();
  const { refetchTranscript } = useContext(AppContext);

  const handleSelectCall = () => {
    onClick(uuid);
    setOpenDrawerMobile?.(!openDrawerMobile);
    navigate(paths.home.reverse({ transcriptId: uuid }));
  };

  const [assignUser] = useMutation(ASSIGN_USER_TO_REVIEW_MUTATION, {
    onCompleted: ({ assignUserToReview }) => {
      toast.success(assignUserToReview.message);
      refetchTranscript?.();
    },
  });

  return (
    <CallItem
      onClick={handleSelectCall}
      className={`${selected ? 'selected-call-item' : ''}`}
    >
      {me.isAcaReviewer && (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <NetworkLabel variant="body1" color="colors.gray08" flexShrink={0}>
              Client Name
            </NetworkLabel>
            <Box display="flex" alignItems="center" textTransform="capitalize" gap="4px">
              {accountAvatarLogo && <AvatarSmall alt={accountName} src={accountAvatarLogo} />}
              <SelectedTypography
                variant="body1"
                color="secondary.main"
                maxWidth="10rem"
                sx={{
                  wordBreak: 'break-word',
                  whiteSpace: 'normal',
                }}
              >
                {accountName}
              </SelectedTypography>
            </Box>
          </Box>
          <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />
        </>
      )}
      <Box display="flex" justifyContent="space-between" mb="2px">
        <Typography
          variant="body1"
          color="colors.gray06"
          sx={{ fontSize: '12px', lineHeight: '18px' }}
        >
          {formatDateTimeToUserTimezone(datetime, me.timezone)}
        </Typography>
        <Typography
          variant="body1"
          color="colors.gray06"
          sx={{ fontSize: '12px', lineHeight: '18px' }}
        >
          Compliance Reviewer
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <IndicateType type={type} />
          {type === 'call' && (
            <Typography variant="body1" display="flex" sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '22px' }}>
              {network}
            </Typography>
          )}
        </Box>
        <ComplianceReviewer
          assignUser={assignUser}
          instanceUuid={review.uuid}
          initialAssignee={review.reviewer}
          placeholder="Unassigned"
          disabled={isResearchAnalyst || reviewStatus === 'Reviewed' || transcriptionStatus !== 'Completed'}
          signedInUserUuid={me.uuid}
          inCard
        />
      </Box>
      <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />
      <Box display="flex" alignItems="center">
        {type === 'call' && (
        <>
          <Box display="flex" flexDirection="column" width="50%" mr="2px">
            <NetworkLabel variant="body1" color="colors.gray08">
              Expert Name
            </NetworkLabel>
            <NetworkValue variant="body1" color="colors.gray08">
              {expertName || 'Not Available'}
            </NetworkValue>
          </Box>
          <Box display="flex" flexDirection="column" width="50%" ml="2px">
            <NetworkLabel variant="body1" color="colors.gray08">
              Analyst
            </NetworkLabel>
            <NetworkValue variant="body1" color="colors.gray08">
              {analystName || 'Not Available'}
            </NetworkValue>
          </Box>
        </>
        )}
      </Box>
      {type === 'call' && <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />}
      <CallTitle variant="body2" color="colors.gray10">
        {title}
      </CallTitle>
      {!isResearchAnalyst && (
        <Box display="flex" alignItems="center" justifyContent="space-between" mt="8px">
          {(pendingHighlights !== undefined && transcriptionStatus === 'Completed' && reviewStatus !== 'Reviewed' && isInSample) && (
            <Box display="flex" alignItems="center">
              <HighlightDot sx={{ backgroundColor: 'colors.warning04' }} />
              <HighlightStatus variant="body1" color="colors.gray08">
                Pending:
              </HighlightStatus>
              <HighlightCount variant="body1" color="colors.gray08">
                {pendingHighlights}
              </HighlightCount>
            </Box>
          )}
          {(resolvedHighlights !== undefined && transcriptionStatus === 'Completed' && isInSample) && (
            <Box display="flex" alignItems="center">
              <HighlightDot sx={{ backgroundColor: 'colors.success03' }} />
              <HighlightStatus variant="body1" color="colors.gray08">
                Resolved:
              </HighlightStatus>
              <HighlightCount variant="body1" color="colors.gray08">
                {resolvedHighlights}
              </HighlightCount>
            </Box>
          )}
          {(aiResolvedHighlights !== undefined && transcriptionStatus === 'Completed' && reviewStatus === 'Reviewed' && isInSample) && (
            <Box display="flex" alignItems="center">
              <HighlightDot sx={{ backgroundColor: 'colors.gray04' }} />
              <HighlightStatus variant="body1" color="colors.gray08">
                AI Resolved:
              </HighlightStatus>
              <HighlightCount variant="body1" color="colors.gray08">
                {aiResolvedHighlights}
              </HighlightCount>
            </Box>
          )}
          {(transcriptionStatus === 'Completed' && !isInSample) && (
            <Box display="flex" alignItems="center">
              <NoteRemoveIcon />
              <HighlightStatus variant="body1" color="colors.gray08">
                Not included in sample.
              </HighlightStatus>
            </Box>
          )}
        </Box>
      )}
      {transcriptionStatus === 'Failed' && (
        <Box display="flex" alignItems="center" mt="8px">
          <FailedIcon />
          <HighlightStatus variant="body1" color="colors.gray08">
            Transcription Failed
          </HighlightStatus>
        </Box>
      )}
      {(transcriptionStatus === 'Transcription In Process' || transcriptionStatus === 'ChatGPT In Process' || transcriptionStatus === 'Text Transcription In Process') && (
        <Box display="flex" alignItems="center" mt="8px">
          <InProgressIcon />
          <HighlightStatus variant="body1" color="colors.gray08">
            Transcription in progress..
          </HighlightStatus>
        </Box>
      )}
      {isResearchAnalyst && createdByResearchAnalyst && (
        <Box display="flex" alignItems="center" mt="8px">
          <UploadedOnRPIcon />
          <HighlightStatus variant="body1" color="colors.gray08">
            Uploaded on research portal
          </HighlightStatus>
        </Box>
      )}
    </CallItem>
  );
};

CallItemCard.defaultProps = {
  pendingHighlights: undefined,
  resolvedHighlights: undefined,
  aiResolvedHighlights: undefined,
  accountAvatarLogo: undefined,
  expertName: null,
  analystName: null,
  isResearchAnalyst: false,
  openDrawerMobile: false,
  setOpenDrawerMobile: false,
};

export default CallItemCard;
